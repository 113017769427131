<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row class="py-4">
          <v-col cols="12" class="text-center text-heading-1">
            <div>
              <v-btn
                depressed
                class="close-button"
                fab
                color="#E27C7C"
                width="20"
                height="20"
                @click="$emit('close-dialog')"
              >
                <v-icon size="14">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="text-center pb-0">
            <img
              :src="preview_file"
              width="100%"
              height="100%"
              class="image_preview mb-2"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'PhotoDialog',
  props: {
    photo: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      preview: null
    }
  },
  computed: {
    preview_file: {
      get() {
        if (this.preview) return this.preview

        return this.data.path
          ? this.data.path_url
          : `${process.env.VUE_APP_ROOT_API}images/no-image.jpg`
      },
      set(value) {
        this.preview = value
      }
    },
    data() {
      if(this.photo) {
        return JSON.parse(JSON.stringify(this.photo))
      } else {
        return {}
      }      
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  color: white;
}
.image_preview {
  object-fit: cover;
}
</style>