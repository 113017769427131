<template>
  <div>
    <!-- {{taskImages}}
    {{selectedProject}} -->
    <div class="customer-box pa-8 mb-6">
      <div class="mb-6">
        <v-row>
          <v-col cols="12">
            <div class="form-header">顧客または物件の入力</div>
          </v-col>
          <v-col cols="8">
            <validation-provider
              v-slot="{ errors }"
              name="customer"
              rules="required"
            >
              <v-autocomplete
                class="customer-box-form-input"
                :error-messages="errors"
                :error="errors.length !== 0"
                hide-details="auto"
                placeholder="物件を選択してください"
                outlined
                :items="customers"
                item-text="name"
                item-value="id"
                return-object
                dense
                color="#4F55A7"
                v-model="_task.customer"
                :search-input.sync="search"
                @update:search-input="$emit('update:search-input', $event)"
                :loading="loading"
                hide-no-data
                cache-items
                @change="_task.site = null"
              >
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                  <template v-if="getSiteData">
                    / <b class="ml-2 mr-4">{{ getSiteData.site_id }}</b>
                    {{ getSiteData.name }}
                  </template>
                </template>
              </v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="auto" class="flex-grow-1 text-right">
            <v-btn
              rounded
              color="white"
              :disabled="!task.customer"
              :to="{
                name: 'CustomerViewDetailBasic',
                params: { customer_id: customerId, site_id: task.site.id }
              }"
              target="_blank"
            >
              詳細
              <v-icon right size="20"> $newTab </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="mb-7" v-if="task.customer && task.project">
        <template v-if="task.customer.address">
          <v-row dense>
            <v-col cols="2" class="customer-box-detail-headline mb-2"
              >住所</v-col
            >
            <v-col cols="4" class="customer-box-detail-content">
              {{ customerAddress }}
            </v-col>
          </v-row>
        </template>
        <template v-if="task.customer.phone1">
          <v-row dense>
            <v-col cols="2" class="customer-box-detail-headline mb-2"
              >電話</v-col
            >
            <v-col cols="4" class="customer-box-detail-content">
              {{ task.customer.phone1 }}
            </v-col>
          </v-row>
        </template>
        <template v-if="task.customer.memo">
          <v-row dense>
            <v-col cols="2" class="customer-box-detail-headline">メモ</v-col>
            <v-col cols="4" class="customer-box-detail-content white-space-pre lh-20">
              {{ task.customer.memo }}
            </v-col>
          </v-row>
        </template>
      </div>

      <v-divider v-if="task.customer && task.project"></v-divider>

      <div class="mt-7">
        <v-row>
          <v-col cols="12">
            <div class="form-header">物件 / サービス内容の選択</div>
          </v-col>
          <v-col cols="4">
            <validation-provider
              v-slot="{ errors }"
              name="site"
              rules="required"
            >
              <v-select
                class="customer-box-form-input"
                :error-messages="errors"
                :error="errors.length !== 0"
                :hide-details="errors.length === 0"
                placeholder="顧客名 / 物件名を入力してください"
                outlined
                dense
                :items="getCustomerSites"
                v-model="_task.site"
                item-name="name"
                item-value="id"
                return-object
                color="#4F55A7"
                @change="siteChange()"
              >
                <template v-slot:selection="{ item }">
                  <b class="mr-4">{{ item.site_id }}</b> {{ item.name }}
                </template>
                <template v-slot:item="{ item }">
                  <b class="mr-4">{{ item.site_id }}</b> {{ item.name }}
                </template>
              </v-select>
            </validation-provider>
          </v-col>
          <v-icon color="rgba(79, 85, 167, 0.5)">mdi-menu-right</v-icon>
          <v-col cols="4">
            <validation-provider
              v-slot="{ errors }"
              name="project"
              rules="required"
            >
              <v-select
                class="customer-box-form-input"
                :error-messages="errors"
                :error="errors.length !== 0"
                :hide-details="errors.length === 0"
                placeholder="サービス内容を選択してください"
                outlined
                dense
                v-model="_task.project"
                :items="projects"
                color="#4F55A7"
                item-text="service_type"
                item-value="id"
                return-object
                :disabled="_task.site === null"
                @change="projectChange()"
              >
                <!-- <template v-slot:value="{ item }">
                {{ item.service.id }}
              </template> -->
                <!--              <template v-slot:selection="{ item }">-->
                <!--                {{ item.service.name }}-->
                <!--              </template>-->
                <!--              <template v-slot:item="{ item }">-->
                <!--                {{ item.service.name }}-->
                <!--              </template>-->
              </v-select>
            </validation-provider>
          </v-col>
          <v-col cols="auto" class="flex-grow-1 text-right" align-self="center">
            <v-btn
              fab
              color="#4F55A7"
              x-small
              depressed
              @click="_expanded = !_expanded"
            >
              <v-icon color="white" size="28">
                mdi-chevron-{{ expanded ? 'up' : 'down' }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <template v-if="_expanded">
        <div class="my-7">
          <template v-if="_task.project">
            <v-row align="center" dense>
              <v-col
                v-if="_task.site.site_id && _task.site.name"
                cols="2"
                class="customer-box-detail-headline"
                >物件名</v-col
              >
              <v-col cols="4" class="customer-box-detail-content">
                {{ _task.site.site_id }} {{ _task.site.name }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                v-if="_task.project.service_type"
                cols="2"
                class="customer-box-detail-headline mb-2"
                >サービス
              </v-col>
              <v-col cols="4" class="customer-box-detail-content">
                {{ _task.project.service_type }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                v-if="_task.site.address"
                cols="2"
                class="customer-box-detail-headline"
                >所在地</v-col
              >
              <v-col cols="4" class="customer-box-detail-content">
                {{ siteAddress }}
              </v-col>
            </v-row>
          </template>
        </div>

        <v-divider v-if="siteHasPartnerData"></v-divider>

        <div class="my-7">
          <template v-if="siteHasPartnerData">
            <v-row dense>
              <template v-if="projectData.partner_0">
                <v-col cols="2" class="customer-box-detail-headline mb-2"
                  >メーカー
                </v-col>
                <v-col cols="4" class="customer-box-detail-content">
                  {{ projectData.partner_0.name }}
                </v-col>
              </template>
              <template v-if="projectData.on_site_monitoring">
                <v-col cols="2" class="customer-box-detail-headline mb-2"
                  >現場立ち合い
                </v-col>
                <v-col cols="4" class="customer-box-detail-content">
                  {{ projectData.on_site_monitoring ? 'あり' : 'なし' }}
                </v-col>
              </template>
            </v-row>
            <v-row dense>
              <template v-if="projectData.partner_2">
                <v-col cols="2" class="customer-box-detail-headline mb-2"
                  >発注会社
                </v-col>
                <v-col cols="4" class="customer-box-detail-content">
                  <!-- partner type 2 info comes here -->
                  {{ projectData.partner_2.name }}
                </v-col>
              </template>
              <template v-if="projectData.partner_1">
                <v-col cols="2" class="customer-box-detail-headline"
                  >元請会社
                </v-col>
                <v-col cols="4" class="customer-box-detail-content">
                  <!-- partner type 1 info comes here -->
                  {{ projectData.partner_1.name }}
                </v-col>
              </template>
            </v-row>
          </template>
        </div>

        <v-divider v-if="_task.project"></v-divider>

        <div v-if="_task.project" class="mt-6">
          <v-row dense>
            <template v-for="itemInfo in filteredItemInfos">
              <v-col
                cols="2"
                class="customer-box-detail-headline"
                :key="`text-${itemInfo.keys}`"
              >
                {{ itemInfo.title }}
              </v-col>
              <v-col
                cols="4"
                class="customer-box-detail-content"
                :key="`value-${itemInfo.keys}`"
              >
                <template v-if="Number.isInteger(projectData[itemInfo.keys])">
                  {{ detailInfo[itemInfo.keys][projectData[itemInfo.keys]] }}
                </template>
                <template v-else v-for="item in projectData[itemInfo.keys]">
                  {{ item.text }}
                </template>
              </v-col>
            </template>
          </v-row>
        </div>
        <div class="mt-6" v-if="projectData.general_guideline">
          <v-divider></v-divider>
          <v-row no-gutters align="baseline" class="mt-4">
            <v-col cols="2" class="customer-box-detail-headline mb-2">
              現場に関するメモ
            </v-col>
            <v-col
              cols="10"
              class="customer-box-detail-content cust-line-height mb-2 text-pre-wrap"
              style="line-height: 1.5"
            >
              {{ projectData.general_guideline || '-' }}
            </v-col>
          </v-row>
        </div>
      </template>
    </div>

    <work-information
      v-if="_expanded"
      :project="task.project"
    ></work-information>

    <claim v-if="_expanded" :loading="loading.claim"></claim>

    <photos v-if="_expanded" :loading="loading.photos"></photos>
  </div>
</template>

<script>
import WorkInformation from '@/components/admin/partials/Task/Edit/CustomerComponents/WorkInformation'
import Claim from '@/components/admin/partials/Task/Edit/CustomerComponents/Claim'
import Photos from '@/components/admin/partials/Task/Edit/CustomerComponents/Photos'
import { mapGetters } from 'vuex'
export default {
  name: 'Customer',
  components: { Photos, Claim, WorkInformation },
  props: {
    expanded: {
      type: Boolean,
      default: true,
      required: false
    },
    task: {
      type: Object,
      default: () => {},
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    ...mapGetters(['allCustomers']),
    _task: {
      get() {
        return this.task
      },
      set(newValue) {
        this.$emit('update:task', newValue)
      }
    },
    _expanded: {
      get() {
        return this.expanded
      },
      set(newValue) {
        this.$emit('update:expanded', newValue)
      }
    },
    customers() {
      let customers = []
      if (this.initialLoad) {
        customers.push(this.task.customer)
      }
      customers.push(...this.allCustomers)
      return customers
    },

    projects() {
      return this.task.site?.projects || []
    },

    getCustomerSites() {
      return this.task.customer?.sites || []
    },

    getSiteData() {
      return this.task.site || null
    },

    siteHasPartnerData() {
      if (!this.projectData) return false
      let site = this.task.site
      return (
        this.projectData.partner_0 ||
        site?.on_site_monitoring ||
        this.projectData.partner_1 ||
        this.projectData.partner_2
      )
    },

    projectData() {
      return this.task.site.projects.find(
        project => project.id === this.task.project_id
      )
    },

    customerAddress() {
      if (
        !this.task.customer.prefecture?.name &&
        !this.task.customer.city &&
        !this.task.customer.address
      )
        return '-'
      return `
      ${this.task.customer.prefecture?.name || ''}
      ${this.task.customer.city || ''}
      ${this.task.customer.address || ''}`
    },

    siteAddress() {
      if (
        !this.task.site.prefecture?.name &&
        !this.task.site.city &&
        !this.task.site.address
      )
        return '-'
      return `
      ${this.task.site.prefecture?.name || ''}
      ${this.task.site.city || ''}
      ${this.task.site.address || ''}`
    },

    customerId() {
      return this.task?.customer?.id
    },

    filteredItemInfos() {
      return this.itemInfos.filter(itemInfo => {
        if (
          this.projectData[itemInfo.keys] !== null &&
          this.projectData[itemInfo.keys]?.length !== 0
        )
          return itemInfo
      })
    }
  },
  data() {
    return {
      search: '',
      initialLoad: true,
      itemInfos: [
        { title: '駐車場', keys: 'parking', has_extra: true },
        { title: 'ブレーカー', keys: 'lightening_braker' },
        { title: '鍵', keys: 'key', is_checkbox: true },
        { title: '電源', keys: 'outlet' },
        { title: '新築/中古', keys: 'site_age' },
        { title: '異常', keys: 'electrical_issues', has_extra: true },
        { title: '入居者', keys: 'residents' },
        { title: 'シャッター', keys: 'shutter' },
        { title: '家具類', keys: 'furnitures' },
        { title: 'カーテン', keys: 'curtain' },
        { title: 'エアコン', keys: 'air_conditioner' }
      ],
      detailInfo: {
        elevator: ['あり', 'なし'],
        parking: ['敷地内', 'コインパーキング', '近隣'],
        lightening_braker: ['入', '切'],
        key: ['立ち合い', 'キーバンカー', 'その他対応'],
        electrical_issues: ['あり', 'なし'],
        site_age: ['新築', '中古'],
        outlet: ['本設', '仮設'],
        residents: ['あり', 'なし'],
        shutter: ['あり', 'なし'],
        furnitures: ['あり', 'なし'],
        curtain: ['あり', 'なし'],
        on_site_monitorings: ['あり', 'なし'],
        air_conditioner: ['あり', '使用', '不使用', '不明', 'なし']
      }
    }
  },
  async mounted() {
    this.initialLoad = false
    await this.projectChange()
  },
  methods: {
    async taskImagesById(id) {
      await this.$store.dispatch('TASK_IMAGES_BY_PROJECT_ID', id)
    },
    siteChange() {
      this._task.project = null
      this._expanded = false
    },
    async projectChange() {
      let id = this.task.project.id
      await this.$store.dispatch('GET_PROJECT_SVC', { id: id })
      this._expanded = true
    },
    showText(array) {
      if (array) {
        let text = array
          .map(c => {
            if (c) {
              if (c.extra_type == 'with_text') {
                return (
                  c.text + ' (' + (c.text_value ? c.text_value : c.value) + ')'
                )
              }
              return c.text ? c.text : c
            } else {
              return ''
            }
          })
          .join(', ')

        return text
      }
    }
  }
}
</script>

<style src="./Customer.scss" lang="scss" scoped></style>
