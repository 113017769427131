var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-4 py-8"
  }, [_c('v-dialog', {
    ref: "photoDialog",
    attrs: {
      "width": "500"
    },
    on: {
      "click:outside": function clickOutside($event) {
        _vm.dialogImage = false;
      }
    },
    model: {
      value: _vm.dialogImage,
      callback: function callback($$v) {
        _vm.dialogImage = $$v;
      },
      expression: "dialogImage"
    }
  }, [_c('photo-dialog', {
    attrs: {
      "value": _vm.dialogImage,
      "photo": _vm.photo
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.dialogImage = $event;
      },
      "close-dialog": function closeDialog($event) {
        _vm.dialogImage = false;
      }
    }
  })], 1), _c('v-dialog', {
    ref: "addPhotoDialog",
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.addPhotoDialog,
      callback: function callback($$v) {
        _vm.addPhotoDialog = $$v;
      },
      expression: "addPhotoDialog"
    }
  }, [_c('AddPhotoDialog', {
    on: {
      "close-dialog": function closeDialog($event) {
        _vm.addPhotoDialog = false;
      }
    }
  })], 1), _c('span', {
    staticClass: "form-header info-box-headline"
  }, [_vm._v(" 写真 ")]), _vm.latestTaskImages.length === 0 ? [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 写真がありません。 ")])], 1)] : _vm._l(_vm.latestTaskImages, function (item) {
    return _c('v-row', {
      key: item.id,
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "col-3"
    }, [_c('v-img', {
      attrs: {
        "src": item.path ? item.path_url : "".concat(_vm.root, "images/no-image.jpg")
      },
      on: {
        "click": function click($event) {
          return _vm.showPhoto(item.id);
        }
      }
    })], 1), _c('v-col', {
      staticClass: "photo-desc",
      attrs: {
        "cols": "6"
      }
    }, [_c('v-row', [_c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('span', {
      staticClass: "mr-3"
    }, [_vm._v(_vm._s(_vm.getDateFormat(item.date)))]), _vm._v(" " + _vm._s(item.updated_by_user ? item.updated_by_user.full_name : '-') + " ")]), _c('v-col', {
      attrs: {
        "cols": "12 text-heading-3"
      }
    }, [_vm._v(" " + _vm._s(item.memo) + " ")])], 1)], 1)], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }