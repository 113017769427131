var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('CarAvailability', {
    attrs: {
      "visibility": _vm.carAvailabilityModal.visibility,
      "loading": _vm.carAvailabilityModal.loading,
      "date": _vm.task.date,
      "time": _vm.task.time
    },
    on: {
      "update:visibility": function updateVisibility($event) {
        return _vm.$set(_vm.carAvailabilityModal, "visibility", $event);
      },
      "click:cancel": function clickCancel($event) {
        _vm.carAvailabilityModal.visibility = false;
      },
      "click:submit": _vm.selectCar
    }
  }), _c('v-row', [_c('v-col', {
    staticClass: "form-header mt-1 text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("参加メンバー")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": "",
      "label": "全員参加",
      "indeterminate": _vm.allIndeterminateCheck,
      "value": 1
    },
    on: {
      "change": function change($event) {
        return _vm.toggleAll();
      }
    },
    model: {
      value: _vm.selectedCategory,
      callback: function callback($$v) {
        _vm.selectedCategory = $$v;
      },
      expression: "selectedCategory"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": "",
      "label": "一般全員",
      "indeterminate": _vm.generalIndeterminateCheck,
      "value": 2
    },
    on: {
      "change": function change($event) {
        ;
        [_vm.toggleGeneral(), _vm.allToggleCheck()];
      }
    },
    model: {
      value: _vm.selectedCategory,
      callback: function callback($$v) {
        _vm.selectedCategory = $$v;
      },
      expression: "selectedCategory"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": "",
      "label": "日常清掃全員",
      "indeterminate": _vm.cleaningIndeterminateCheck,
      "value": 3
    },
    on: {
      "change": function change($event) {
        ;
        [_vm.toggleCleaning(), _vm.allToggleCheck()];
      }
    },
    model: {
      value: _vm.selectedCategory,
      callback: function callback($$v) {
        _vm.selectedCategory = $$v;
      },
      expression: "selectedCategory"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "user-type-label text-heading-3"
  }, [_vm._v("一般社員")]), _c('div', {
    staticClass: "user-type-label-divider"
  })]), _c('validation-provider', {
    attrs: {
      "name": "selectedGeneral",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-radio-group', {
          staticClass: "mt-0",
          attrs: {
            "row": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0
          }
        }, _vm._l(_vm.generalUsers, function (user, userIndex) {
          return _c('v-checkbox', {
            key: "general-user-".concat(userIndex),
            staticClass: "mx-2 mt-0",
            attrs: {
              "value": user,
              "label": "".concat(user.text)
            },
            on: {
              "change": function change($event) {
                return _vm.userTypeToggleCheck('general', user.id, $event);
              }
            },
            model: {
              value: _vm._task.selectedGeneral,
              callback: function callback($$v) {
                _vm.$set(_vm._task, "selectedGeneral", $$v);
              },
              expression: "_task.selectedGeneral"
            }
          });
        }), 1)];
      }
    }])
  })], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "user-type-label text-heading-3"
  }, [_vm._v("日常清掃員")]), _c('div', {
    staticClass: "user-type-label-divider"
  })]), _c('validation-provider', {
    attrs: {
      "name": "selectedCleaning",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-radio-group', {
          staticClass: "mt-0",
          attrs: {
            "row": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0
          }
        }, _vm._l(_vm.cleaningUsers, function (user, userIndex) {
          return _c('v-checkbox', {
            key: "cleaning-user-".concat(userIndex),
            staticClass: "mx-2 mt-0",
            attrs: {
              "value": user,
              "label": "".concat(user.text)
            },
            on: {
              "change": function change($event) {
                return _vm.userTypeToggleCheck('cleaning', user.id, $event);
              }
            },
            model: {
              value: _vm._task.selectedCleaning,
              callback: function callback($$v) {
                _vm.$set(_vm._task, "selectedCleaning", $$v);
              },
              expression: "_task.selectedCleaning"
            }
          });
        }), 1)];
      }
    }])
  })], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("リーダー")]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "leader",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          staticClass: "form-text",
          style: _vm.selectedUsers.length < 2 ? "pointer-events: none" : '',
          attrs: {
            "dense": "",
            "outlined": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "items": _vm.selectedUsers,
            "item-text": "text",
            "item-value": "id",
            "placeholder": "選択"
          },
          on: {
            "change": function change($event) {
              return _vm.onLeaderChange();
            }
          },
          model: {
            value: _vm._task.leader_id,
            callback: function callback($$v) {
              _vm.$set(_vm._task, "leader_id", $$v);
            },
            expression: "_task.leader_id"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right",
    attrs: {
      "cols": "3",
      "md": "2",
      "order": "3"
    }
  }, [_vm._v("車")]), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "3",
      "order": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "vehicle",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "outlined": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "items": _vm._allCars,
            "item-text": "name",
            "item-value": "id",
            "placeholder": "選択"
          },
          model: {
            value: _vm._task.car_id,
            callback: function callback($$v) {
              _vm.$set(_vm._task, "car_id", $$v);
            },
            expression: "_task.car_id"
          }
        })];
      }
    }])
  })], 1), _c('v-col', {
    staticClass: "form-header text-right",
    attrs: {
      "cols": "3",
      "md": "1",
      "order": "6"
    }
  }, [_vm._v("ETC")]), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "2",
      "order": "7"
    }
  }, [_c('v-btn-toggle', {
    staticClass: "btn-toggle",
    model: {
      value: _vm._task.ETC_card,
      callback: function callback($$v) {
        _vm.$set(_vm._task, "ETC_card", $$v);
      },
      expression: "_task.ETC_card"
    }
  }, [_c('v-btn', {
    attrs: {
      "height": "32",
      "value": 1
    }
  }, [_vm._v(" 有 ")]), _c('v-btn', {
    attrs: {
      "height": "32",
      "value": 0
    }
  }, [_vm._v(" 無 ")])], 1), _c('span', {
    staticClass: "unselect",
    on: {
      "click": function click($event) {
        _vm._task.ETC_card = null;
      }
    }
  }, [_vm._v("選択解除")])], 1), _c('v-col', {
    staticClass: "form-header text-right",
    attrs: {
      "cols": "3",
      "md": "1",
      "order": "8"
    }
  }, [_vm._v(" ガソリンカード ")]), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "2",
      "order": "9"
    }
  }, [_c('v-btn-toggle', {
    staticClass: "btn-toggle",
    model: {
      value: _vm._task.gasoline_card,
      callback: function callback($$v) {
        _vm.$set(_vm._task, "gasoline_card", $$v);
      },
      expression: "_task.gasoline_card"
    }
  }, [_c('v-btn', {
    attrs: {
      "height": "32",
      "value": 1
    }
  }, [_vm._v(" 有 ")]), _c('v-btn', {
    attrs: {
      "height": "32",
      "value": 0
    }
  }, [_vm._v(" 無 ")])], 1), _c('span', {
    staticClass: "unselect",
    on: {
      "click": function click($event) {
        _vm._task.gasoline_card = null;
      }
    }
  }, [_vm._v("選択解除")])], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "offset": "3",
      "offset-md": "2",
      "order": "5",
      "order-md": "12"
    }
  }, [_c('a', {
    on: {
      "click": _vm.showCarAvailabilityModal
    }
  }, [_vm._v("空き車両の確認")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-6 form-header text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("外注先")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_vm._l(_vm.type1Partners, function (partner, partnerIndex) {
    return [_c('v-col', {
      key: "outsource-staff-".concat(partnerIndex),
      staticClass: "text-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "value": partner
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [partner.profile_image ? _c('v-avatar', {
            staticClass: "mr-2",
            attrs: {
              "size": "40"
            }
          }, [_c('img', {
            attrs: {
              "alt": "avatar",
              "src": _vm.getPartnerProfilePicture(partner)
            }
          })]) : _vm._e(), _vm._v(" " + _vm._s(partner.name) + " ")];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm._task.selectedPartners,
        callback: function callback($$v) {
          _vm.$set(_vm._task, "selectedPartners", $$v);
        },
        expression: "_task.selectedPartners"
      }
    })], 1), _vm._task.selectedPartners.find(function (item) {
      return item.id === partner.id;
    }) ? _c('v-col', {
      key: "outsource-staff-".concat(partnerIndex, "-select"),
      staticStyle: {
        "max-width": "80px"
      }
    }, [_c('v-select', {
      staticClass: "form-text outsource",
      attrs: {
        "outlined": "",
        "hide-details": "auto",
        "dense": "",
        "value": _vm.getFreelancerCount(partner),
        "items": _vm.freelancersCount
      },
      on: {
        "change": function change($event) {
          return _vm.updateTotalFreelanceForPartner($event, partner);
        }
      }
    })], 1) : _vm._e()];
  })], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }