var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "customer-box pa-8 mb-6"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header"
  }, [_vm._v("顧客または物件の入力")])]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "customer",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-autocomplete', {
          staticClass: "customer-box-form-input",
          attrs: {
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": "auto",
            "placeholder": "物件を選択してください",
            "outlined": "",
            "items": _vm.customers,
            "item-text": "name",
            "item-value": "id",
            "return-object": "",
            "dense": "",
            "color": "#4F55A7",
            "search-input": _vm.search,
            "loading": _vm.loading,
            "hide-no-data": "",
            "cache-items": ""
          },
          on: {
            "update:searchInput": function updateSearchInput($event) {
              _vm.search = $event;
            },
            "update:search-input": [function ($event) {
              _vm.search = $event;
            }, function ($event) {
              return _vm.$emit('update:search-input', $event);
            }],
            "change": function change($event) {
              _vm._task.site = null;
            }
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_vm._v(" " + _vm._s(item.name) + " "), _vm.getSiteData ? [_vm._v(" / "), _c('b', {
                staticClass: "ml-2 mr-4"
              }, [_vm._v(_vm._s(_vm.getSiteData.site_id))]), _vm._v(" " + _vm._s(_vm.getSiteData.name) + " ")] : _vm._e()];
            }
          }], null, true),
          model: {
            value: _vm._task.customer,
            callback: function callback($$v) {
              _vm.$set(_vm._task, "customer", $$v);
            },
            expression: "_task.customer"
          }
        })];
      }
    }])
  })], 1), _c('v-col', {
    staticClass: "flex-grow-1 text-right",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "rounded": "",
      "color": "white",
      "disabled": !_vm.task.customer,
      "to": {
        name: 'CustomerViewDetailBasic',
        params: {
          customer_id: _vm.customerId,
          site_id: _vm.task.site.id
        }
      },
      "target": "_blank"
    }
  }, [_vm._v(" 詳細 "), _c('v-icon', {
    attrs: {
      "right": "",
      "size": "20"
    }
  }, [_vm._v(" $newTab ")])], 1)], 1)], 1)], 1), _vm.task.customer && _vm.task.project ? _c('div', {
    staticClass: "mb-7"
  }, [_vm.task.customer.address ? [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("住所")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.customerAddress) + " ")])], 1)] : _vm._e(), _vm.task.customer.phone1 ? [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("電話")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.task.customer.phone1) + " ")])], 1)] : _vm._e(), _vm.task.customer.memo ? [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("メモ")]), _c('v-col', {
    staticClass: "customer-box-detail-content white-space-pre lh-20",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.task.customer.memo) + " ")])], 1)] : _vm._e()], 2) : _vm._e(), _vm.task.customer && _vm.task.project ? _c('v-divider') : _vm._e(), _c('div', {
    staticClass: "mt-7"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header"
  }, [_vm._v("物件 / サービス内容の選択")])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "site",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          staticClass: "customer-box-form-input",
          attrs: {
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "placeholder": "顧客名 / 物件名を入力してください",
            "outlined": "",
            "dense": "",
            "items": _vm.getCustomerSites,
            "item-name": "name",
            "item-value": "id",
            "return-object": "",
            "color": "#4F55A7"
          },
          on: {
            "change": function change($event) {
              return _vm.siteChange();
            }
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [_c('b', {
                staticClass: "mr-4"
              }, [_vm._v(_vm._s(item.site_id))]), _vm._v(" " + _vm._s(item.name) + " ")];
            }
          }, {
            key: "item",
            fn: function fn(_ref5) {
              var item = _ref5.item;
              return [_c('b', {
                staticClass: "mr-4"
              }, [_vm._v(_vm._s(item.site_id))]), _vm._v(" " + _vm._s(item.name) + " ")];
            }
          }], null, true),
          model: {
            value: _vm._task.site,
            callback: function callback($$v) {
              _vm.$set(_vm._task, "site", $$v);
            },
            expression: "_task.site"
          }
        })];
      }
    }])
  })], 1), _c('v-icon', {
    attrs: {
      "color": "rgba(79, 85, 167, 0.5)"
    }
  }, [_vm._v("mdi-menu-right")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "project",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-select', {
          staticClass: "customer-box-form-input",
          attrs: {
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "placeholder": "サービス内容を選択してください",
            "outlined": "",
            "dense": "",
            "items": _vm.projects,
            "color": "#4F55A7",
            "item-text": "service_type",
            "item-value": "id",
            "return-object": "",
            "disabled": _vm._task.site === null
          },
          on: {
            "change": function change($event) {
              return _vm.projectChange();
            }
          },
          model: {
            value: _vm._task.project,
            callback: function callback($$v) {
              _vm.$set(_vm._task, "project", $$v);
            },
            expression: "_task.project"
          }
        })];
      }
    }])
  })], 1), _c('v-col', {
    staticClass: "flex-grow-1 text-right",
    attrs: {
      "cols": "auto",
      "align-self": "center"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "color": "#4F55A7",
      "x-small": "",
      "depressed": ""
    },
    on: {
      "click": function click($event) {
        _vm._expanded = !_vm._expanded;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white",
      "size": "28"
    }
  }, [_vm._v(" mdi-chevron-" + _vm._s(_vm.expanded ? 'up' : 'down') + " ")])], 1)], 1)], 1)], 1), _vm._expanded ? [_c('div', {
    staticClass: "my-7"
  }, [_vm._task.project ? [_c('v-row', {
    attrs: {
      "align": "center",
      "dense": ""
    }
  }, [_vm._task.site.site_id && _vm._task.site.name ? _c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("物件名")]) : _vm._e(), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm._task.site.site_id) + " " + _vm._s(_vm._task.site.name) + " ")])], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_vm._task.project.service_type ? _c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("サービス ")]) : _vm._e(), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm._task.project.service_type) + " ")])], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_vm._task.site.address ? _c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("所在地")]) : _vm._e(), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.siteAddress) + " ")])], 1)] : _vm._e()], 2), _vm.siteHasPartnerData ? _c('v-divider') : _vm._e(), _c('div', {
    staticClass: "my-7"
  }, [_vm.siteHasPartnerData ? [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_vm.projectData.partner_0 ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("メーカー ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.projectData.partner_0.name) + " ")])] : _vm._e(), _vm.projectData.on_site_monitoring ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("現場立ち合い ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.projectData.on_site_monitoring ? 'あり' : 'なし') + " ")])] : _vm._e()], 2), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_vm.projectData.partner_2 ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("発注会社 ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.projectData.partner_2.name) + " ")])] : _vm._e(), _vm.projectData.partner_1 ? [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("元請会社 ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.projectData.partner_1.name) + " ")])] : _vm._e()], 2)] : _vm._e()], 2), _vm._task.project ? _c('v-divider') : _vm._e(), _vm._task.project ? _c('div', {
    staticClass: "mt-6"
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.filteredItemInfos, function (itemInfo) {
    return [_c('v-col', {
      key: "text-".concat(itemInfo.keys),
      staticClass: "customer-box-detail-headline",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(itemInfo.title) + " ")]), _c('v-col', {
      key: "value-".concat(itemInfo.keys),
      staticClass: "customer-box-detail-content",
      attrs: {
        "cols": "4"
      }
    }, [Number.isInteger(_vm.projectData[itemInfo.keys]) ? [_vm._v(" " + _vm._s(_vm.detailInfo[itemInfo.keys][_vm.projectData[itemInfo.keys]]) + " ")] : _vm._l(_vm.projectData[itemInfo.keys], function (item) {
      return [_vm._v(" " + _vm._s(item.text) + " ")];
    })], 2)];
  })], 2)], 1) : _vm._e(), _vm.projectData.general_guideline ? _c('div', {
    staticClass: "mt-6"
  }, [_c('v-divider'), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "no-gutters": "",
      "align": "baseline"
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 現場に関するメモ ")]), _c('v-col', {
    staticClass: "customer-box-detail-content cust-line-height mb-2 text-pre-wrap",
    staticStyle: {
      "line-height": "1.5"
    },
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.projectData.general_guideline || '-') + " ")])], 1)], 1) : _vm._e()] : _vm._e()], 2), _vm._expanded ? _c('work-information', {
    attrs: {
      "project": _vm.task.project
    }
  }) : _vm._e(), _vm._expanded ? _c('claim', {
    attrs: {
      "loading": _vm.loading.claim
    }
  }) : _vm._e(), _vm._expanded ? _c('photos', {
    attrs: {
      "loading": _vm.loading.photos
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }