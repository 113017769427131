<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row class="py-4">
          <v-col cols="12" class="text-center text-heading-1">
            写真管理
            <div>
              <v-btn
                depressed
                class="close-button"
                fab
                color="#E27C7C"
                width="20"
                height="20"
                @click="$emit('close-dialog')"
              >
                <v-icon size="14">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="text-center pb-0">
            <input
              type="file"
              ref="file"
              style="display: none"
              @change="onFileChange"
              accept="image/png, image/gif, image/jpeg"
            />
            <v-btn height="200" block depressed @click="$refs.file.click()">
              <v-icon v-if="!choosenPhoto" size="180">$imagePlaceholder</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-btn color="#AD4545" text> - 写真削除 </v-btn>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn color="#336ABB" text> + 画像アップロード </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="form-header mb-2">実施日</div>
            <div>
              <v-text-field
                class="form-text"
                dense
                outlined
                placeholder="日付選択"
                hide-details
                v-model="data.name"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="form-header mb-2">説明文</div>
            <div>
              <v-textarea
                class="form-text"
                dense
                outlined
                placeholder="画像の説明文"
                hide-details
                v-model="data.description"
              ></v-textarea>
              <div class="text-right">
                {{ data.description.length }} / {{ descriptionMaxLength }} 文字
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <v-row align="center">
              <v-col cols="6">
                <v-btn color="#AD4545" text>
                  <v-icon left>$trash</v-icon>
                  削除する
                </v-btn>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-col cols="auto">
                  <v-btn
                    color="#4F55A7"
                    class="white--text"
                    width="100"
                    @click="addTaskImage"
                    >登録
                  </v-btn>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Dialog',
  data() {
    return {
      data: {
        choosenPhoto: null,
        name: '',
        description: ''
      },
      descriptionMaxLength: 500
    }
  },
  methods: {
    addTaskImage() {
      const formData = new FormData()
      formData.append(
        'filename',
        this.data.choosenPhoto,
        this.data.choosenPhoto.name
      )
      formData.append('explanation', this.data.description)
      this.$store.dispatch('ADD_TASK_IMAGES', formData).then(
        response => {
          if (response.data.status == 'success') {
            this.$router.push({
              path: '/management/employee',
              query: { status: 'success', message: response.data.data.message }
            })
          }
        },
        error => {
          throw error
        }
      )
    },
    onFileChange(event) {
      this.data.choosenPhoto = event.target.files[0]
    }
  }
}
</script>

<style lang="scss" src="./AddTaskImages.scss" scoped></style>
