<template>
  <div class="mt-4 py-8">
    <v-dialog
      ref="photoDialog"
      v-model="dialogImage"
      @click:outside="dialogImage = false"
      width="500"
    >
      <photo-dialog
        :value.sync="dialogImage"
        :photo="photo"
        @close-dialog="dialogImage = false"
      >
      </photo-dialog>
    </v-dialog>

    <v-dialog ref="addPhotoDialog" v-model="addPhotoDialog" width="500">
      <AddPhotoDialog @close-dialog="addPhotoDialog = false"></AddPhotoDialog>
    </v-dialog>
    <span class="form-header info-box-headline"> 写真 </span>
    <!-- TODO: Clarify photos for task -->
    <template v-if="latestTaskImages.length === 0" class="mt-4 pt-6">
      <v-row>
        <v-col cols="12" class="text-center">
          写真がありません。
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row v-for="item in latestTaskImages" :key="item.id" align="center">
        <v-col class="col-3">
          <v-img
            @click="showPhoto(item.id)"
            :src="item.path ? item.path_url : `${root}images/no-image.jpg`"
          ></v-img>
        </v-col>
        <v-col cols="6" class="photo-desc">
          <v-row>
            <v-col cols="12" class="pb-0">
              <span class="mr-3">{{ getDateFormat(item.date) }}</span>
              {{ item.updated_by_user ? item.updated_by_user.full_name : '-' }}
            </v-col>
            <v-col cols="12 text-heading-3">
              {{ item.memo }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddPhotoDialog from './AddTaskImages.vue'
import PhotoDialog from '@/components/admin/partials/Task/Create/CustomerComponents/PhotoDialog'
export default {
  name: 'Photos',
  components: { PhotoDialog, AddPhotoDialog },
  data() {
    return {
      addPhotoDialog: false,
      photo: null,
      dialogImage: false
    }
  },
  computed: {
    ...mapGetters(['task']),
    latestTaskImages() {
      return this.task.photos.slice(0, 5)
    }
  },
  methods: {
    getDateFormat(claim_date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(claim_date).toLocaleDateString('ja-JP', options)
    },
    showPhoto(id) {
      this.photo = this.latestTaskImages.find(c => c.id == id)
      this.dialogImage = true
    },
    addPhoto() {
      this.addPhotoDialog = true
    }
  }
}
</script>

<style lang="scss" src="./Photos.scss" scoped></style>