<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="form-header">
        実施日
      </v-col>
      <v-col cols="4" class="flex-grow-1"
        ><v-dialog ref="editDialog" v-model="modalPickerDate" width="290px">
          <template v-slot:activator="{ on, attrs }">
            <validation-provider
              v-slot="{ errors }"
              name="date"
              rules="required"
            >
              <v-text-field
                v-model="_task.date"
                readonly
                class="form-text"
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                placeholder="日付選択"
                :error-messages="errors"
                :error="errors.length !== 0"
                :hide-details="errors.length === 0"
              ></v-text-field>
            </validation-provider>
          </template>
          <v-date-picker
            :day-format="formatDate"
            v-model="_task.date"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalPickerDate = false">
              キャンセル
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.editDialog.save(_task.date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="auto" class="form-header">
        集合開始
      </v-col>
      <v-col cols="10" class="">
        <v-row align="center">
          <v-col cols="auto">
            開始
          </v-col>
          <v-col cols="2" class="">
            <v-dialog
              ref="dialogStartTime"
              v-model="modalPickerStartTime"
              :return-value.sync="_task.task_start_time"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider v-slot="{ errors }" name="time" rules="">
                  <v-text-field
                    v-model="_task.task_start_time"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="form-text"
                    dense
                    outlined
                    placeholder="--.--"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    :hide-details="errors.length === 0"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-time-picker
                v-if="modalPickerStartTime"
                v-model="_task.task_start_time"
                full-width
                @change="updateGatheringTime"
                ampm-in-title
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modalPickerStartTime = false"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    ;[
                      $refs.dialogStartTime.save(_task.task_start_time),
                      updateGatheringTime(_task.task_start_time)
                    ]
                  "
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col cols="auto">
            集合
          </v-col>
          <v-col cols="2" class="">
            <v-dialog
              ref="dialogGatherTime"
              v-model="modalPickerGatherTime"
              :return-value.sync="_task.gathering_time"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  name="gathering_time"
                  rules=""
                >
                  <v-text-field
                    v-model="_task.gathering_time"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="form-text"
                    dense
                    outlined
                    placeholder="--.--"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    :hide-details="errors.length === 0"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-time-picker
                v-if="modalPickerGatherTime"
                v-model="_task.gathering_time"
                full-width
                ampm-in-title
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modalPickerGatherTime = false"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogGatherTime.save(_task.gathering_time)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col cols="auto">
            集合場所
          </v-col>
          <v-col cols="3" class="">
            <validation-provider
              v-slot="{ errors }"
              name="gathering_place"
              rules=""
            >
              <v-select
                v-model="_task.gathering_place"
                class="form-text"
                :items="meetingPlaces"
                dense
                outlined
                placeholder="選択"
                item-value="value"
                item-text="value"
                :error-messages="errors"
                :error="errors.length !== 0"
                :hide-details="errors.length === 0"
              >
              </v-select>
            </validation-provider>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="auto" class="form-header">
        完了時刻
      </v-col>
      <v-col cols="10" class="">
        <v-row align="center">
          <v-col cols="2" class="">
            <v-dialog
              ref="dialogEndTime"
              v-model="modalPickerEndTime"
              :return-value.sync="_task.task_end_time"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider v-slot="{ errors }" name="time" rules="">
                  <v-text-field
                    v-model="_task.task_end_time"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="form-text"
                    dense
                    outlined
                    placeholder="--.--"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    :hide-details="errors.length === 0"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-time-picker
                v-if="modalPickerEndTime"
                v-model="_task.task_end_time"
                full-width
                ampm-in-title
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalPickerEndTime = false">
                  キャンセル
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    ;[
                      $refs.dialogEndTime.save(_task.task_end_time),
                      updateEndTime(_task.task_end_time)
                    ]
                  "
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'DateTime',
  props: {
    task: {
      type: Object,
      default: () => {},
      required: true
    }
  },

  computed: {
    _task: {
      get() {
        return this.task
      },
      set(newValue) {
        this.$emit('update:task', newValue)
      }
    },
    isDay() {
      if (!this.task.task_start_time) return true
      let hour = parseInt(this.task.task_start_time.split(':')[0])
      let minute = parseInt(this.task.task_start_time.split(':')[1])
      let time = dayjs()
        .hour(hour)
        .minute(minute)
      let dayThresholdStart = time.hour(5)
      let dayThresholdEnd = time
        .hour(17)
        .minute(59)
        .second(59)

      return time.isAfter(dayThresholdStart) && time.isBefore(dayThresholdEnd)
    }
  },
  watch: {
    '_task.task_start_time': {
      handler() {
        this._task.time = this.isDay ? 0 : 1
      },
      deep: true
    }
  },
  data() {
    return {
      modalPickerDate: false,
      modalPickerStartTime: false,
      modalPickerGatherTime: false,
      modalPickerEndTime: false,
      meetingPlaces: [
        {
          id: 0,
          value: '会社'
        },
        {
          id: 1,
          value: '現場'
        },
        {
          id: 2,
          value: '指定場所'
        }
      ],
      colors: [
        {
          label: 'コート',
          value: 'coat'
        },
        {
          label: 'ビルメン',
          value: 'building'
        },
        {
          label: 'リニ工事',
          value: 'construction'
        },
        {
          label: '事務所',
          value: 'office'
        },
        {
          label: 'イベント',
          value: 'event'
        }
      ]
    }
  },
  methods: {
    updateGatheringTime(e) {
      let hour = e.split(':')[0]
      let minute = e.split(':')[1]
      let startTime = dayjs()
        .hour(hour)
        .minute(minute)
      this._task.gathering_time = startTime
        .subtract(30, 'minute')
        .format('HH:mm')
    },
    updateEndTime(e) {
      let hour = e.split(':')[0]
      let minute = e.split(':')[1]
      let startTime = dayjs()
        .hour(hour)
        .minute(minute)
      this._task.task_end_time = startTime.format('HH:mm')
    },
    formatDate(e) {
      return dayjs(e).format('D')
    },
    updateDate(e) {
      this._task.date = e
    }
  }
}
</script>

<style lang="scss" src="./DateTime.scss" scoped></style>
