<template>
  <validation-observer ref="observer">
    <v-container v-if="dataLoading">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <form v-else @submit.prevent="editTask">
      <div class="task-container pa-9 pa-md-6 pa-lg-9">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h3 class="page-title font-weight-bold">タスクの編集</h3>
            </v-col>
          </v-row>
          <v-row id="customerPart" ref="customerPart">
            <v-col cols="12">
              <div class="text-title text-title-2 text-heading-2 font-weight-bold">
                顧客 / 台帳
              </div>
              <div class="horizontal-line"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="form-content">
                <customer :task="task" :expanded.sync="expanded" :loading="searchCustomerLoading"
                  @update:search-input="findCustomer"></customer>
              </div>
            </v-col>
          </v-row>

          <v-row id="dateTimePart" ref="dateTimePart" class="mb-6">
            <v-col cols="12" class="mb-2">
              <div class="text-title text-title-2 text-heading-2 font-weight-bold">
                日時
              </div>
              <div class="horizontal-line"></div>
            </v-col>
            <v-col cols="12">
              <div class="form-content">
                <date-time :task="task" color="coat"></date-time>
              </div>
            </v-col>
          </v-row>

          <v-row id="memoPart" ref="memoPart" class="mb-6">
            <v-col cols="12" class="mb-2">
              <div class="text-title text-title-2 text-heading-2 font-weight-bold">
                メモ
              </div>
              <div class="horizontal-line"></div>
            </v-col>
            <v-col cols="12">
              <div class="form-content">
                <memo :normal.sync="task.memo" :important.sync="task.important_notice"></memo>
              </div>
            </v-col>
          </v-row>

          <v-row id="transportationPart" ref="transportationPart">
            <v-col cols="12" class="mb-2">
              <div class="text-title text-title-2 text-heading-2 font-weight-bold">
                チーム / 車
              </div>
              <div class="horizontal-line"></div>
            </v-col>
            <v-col cols="12">
              <div class="form-content">
                <transportation :task.sync="task"></transportation>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="create-task-footer" :class="!isIntersecting ? 'shadow' : ''">
        <v-container>
          <v-row>
            <v-col cols="auto" class="flex-grow-1">
              <v-btn color="#AD4545" text @click="deleteTask" :loading="loading">
                <v-icon left>$trash</v-icon>
                削除する
              </v-btn>
            </v-col>

            <v-col cols="auto" class="flex-grow-1">
              <v-btn class="mx-2" :color="isSectionActive('customer')" depressed rounded
                @click="$vuetify.goTo('#customerPart', { offset: offset })">
                顧客 / 台帳
              </v-btn>
              <v-btn class="mx-2" :color="isSectionActive('dateTime')" depressed rounded
                @click="$vuetify.goTo('#dateTimePart', { offset: offset })">
                日時
              </v-btn>
              <v-btn class="mx-2" :color="isSectionActive('memo')" depressed rounded
                @click="$vuetify.goTo('#memoPart', { offset: offset })">
                メモ
              </v-btn>
              <v-btn class="mx-2" :color="isSectionActive('transportation')" depressed rounded @click="
                $vuetify.goTo('#transportationPart', { offset: offset })
              ">
                チーム / 車
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn :to="{ name: 'TaskView', params: { id: $route.params.id } }" text>
                キャンセル
              </v-btn>
            </v-col>

            <v-col cols="auto" style="display:none">
              <v-btn outlined color="#2F80ED">
                <v-icon size="32" left>$sendMail</v-icon>
                通知
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn color="#4F55A7" class="white--text" width="100" :loading="loading" @click="editTask">更新
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import Customer from '@/components/admin/partials/Task/Edit/Customer'
import DateTime from '@/components/admin/partials/Task/Edit/DateTime'
import Memo from '@/components/admin/partials/Task/Edit/Memo'
import Transportation from '@/components/admin/partials/Task/Edit/Transportation'
import { debounce } from 'lodash'
import { mapGetters, mapMutations } from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'Edit',
  components: { Transportation, Memo, DateTime, Customer },
  data() {
    return {
      task: {
        customer: null,
        site: null,
        project: null,
        name: '',
        task_start_time: '08:00',
        gathering_time: '07:30',
        gathering_place: '',
        date: dayjs().format('YYYY-MM-DD'),
        time: 0,
        leader_id: null,
        car_id: null,
        priority: 0,
        project_id: null,
        service_type_id: null,
        suspense_payment: null,
        task_freelancer_users: null,
        task_start_date: null,
        task_type_id: null,
        notified_at: dayjs().format('YYYY-MM-DD'),
        selectedGeneral: [],
        selectedCleaning: [],
        selectedPartners: []
      },
      offset: 75,
      expanded: false,
      isIntersecting: false,
      btnActive: 'rgba(79, 85, 167, 0.45)',
      btnRegular: 'rgba(79, 85, 167, 0.1)',
      customers: [],
      searchCustomerLoading: false,
      dataLoading: false,
      loading: false,
      on: 'customerPart',
      originalLeader: null,
      originalEmployees: []
    }
  },

  async created() {
    await this.getTaskData()
    await this.fetchTaskClaims()
    await this.fetchTaskImages()
  },

  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },

  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },

  computed: {
    ...mapGetters(['allCustomers', 'allPartners']),
    taskDetails() {
      return this.$store.getters.task
    }
  },

  methods: {
    ...mapMutations(['showModal']),

    async getTaskData() {
      this.dataLoading = true
      await this.$store.dispatch('GET_TASK_BY_ID', this.$route.params.id)
      for (const [key, value] of Object.entries(this.taskDetails)) {
        this.task[key] = value
      }
      await this.getUserData()
      await this.getCarData()
      await this.getPartners()
      this.dataLoading = false
      this.originalLeader = this.task.leader_id
      this.originalEmployees = this.task.task_member
        .map(member => member.id)
        .sort()
      document.title = `${this.taskDetails.customer.name} - ${document.title}`
    },

    async getUserData() {
      await this.$store.dispatch('USER_GET_ALL')
    },

    async getCarData() {
      await this.$store.dispatch('CAR_GET_ALL')
    },

    async getPartners() {
      let param = {
        partner: {
          type: 3
        }
      }
      await this.$store.dispatch('PARTNER_GET_ALL', param.partner)
    },

    findCustomer: debounce(function (query) {
      if (query) {
        this.task.site = null
        this.searchCustomerLoading = true
        let params = {
          search: query
        }
        this.$store.dispatch('CUSTOMER_GET_ALL', params).finally(() => {
          this.searchCustomerLoading = false
        })
      }
    }, 1000),

    editTask() {
      let employees = this.task.selectedGeneral
        .concat(this.task.selectedCleaning)
        .map(item => item.id)
        .sort()

      let employeeDoesntChange =
        employees.length === this.originalEmployees.length &&
        employees.every((element, index) => {
          return element === this.originalEmployees[index]
        })

      if (this.task.leader_id === null) {
        this.showModal({
          text:
            'このタスクではリーダーが選択されていません。続行してもよろしいですか？',
          action: {
            process: {
              color: 'red',
              text: '続行する'
            },
            cancel: {
              color: 'secondary',
              text: 'キャンセル'
            }
          },
          func: this.processEdit
        })
      } else if (
        this.originalLeader !== this.task.leader_id ||
        !employeeDoesntChange
      ) {
        this.showModal({
          text: '変更内容を保存しますか？',
          action: {
            process: {
              color: 'red',
              text: '続行する'
            },
            cancel: {
              color: 'secondary',
              text: 'キャンセル'
            }
          },
          func: this.processEdit
        })
      } else {
        this.processEdit()
      }
    },

    deleteTask() {
      this.showModal({
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.processDelete
      })
    },

    isSectionActive(section) {
      return this.on == `${section}Part` ? this.btnActive : this.btnRegular
    },

    updateScroll() {
      let scrollPosition = window.scrollY
      this.on = 'customerPart'
      if (scrollPosition >= this.$refs.transportationPart?.offsetTop - this.offset) {
        this.on = 'transportationPart'
      } else if (scrollPosition >= this.$refs.memoPart?.offsetTop - this.offset) {
        this.on = 'memoPart'
      } else if (scrollPosition >= this.$refs.dateTimePart?.offsetTop - this.offset) {
        this.on = 'dateTimePart'
      } else if (scrollPosition >= this.$refs.customerPart?.offsetTop - this.offset) {
        this.on = 'customerPart'
      }
    },

    processEdit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        this.loading = true
        this.task.customer_id = this.task.customer.id
        this.task.site_id = this.task.site.id
        this.task.project_id = this.task.project.id
        this.task.service_type_id = this.task.project.service_type_id
        this.task.task_start_date = this.task.date

        this.task.task_members = this.task.selectedGeneral
          .concat(this.task.selectedCleaning)
          .map(item => item.id)

        this.task.partners = this.task.selectedPartners.map(partner => {
          return {
            id: partner.id,
            freelancers_count: partner.freelancers_count
          }
        })
        this.$store
          .dispatch('EDIT_TASK', { id: this.$route.params.id, data: this.task })
          .then(
            response => {
              if (response.data.status === 'success') {
                // ScheduleTaskView
                this.$router.push({
                  name: 'ScheduleTaskView',
                  // params: {
                  //   id: this.$route.params.id
                  // },
                  query: {
                    status: 'success',
                    date: this.task.date,
                    task: this.task.id,
                    message: 'タスクを更新しました'
                  }
                })
              }
            },
            error => {
              throw error
            }
          )
          .finally(() => {
            this.loading = false
          })
      })
    },

    processDelete() {
      this.loading = true
      this.$store
        .dispatch('DELETE_TASK', this.$route.params.id)
        .then(
          response => {
            if (response.data.status === 'success') {
              this.$router.push({
                name: 'ScheduleMonthly'
              })
            }
          },
          error => {
            throw error
          }
        )
        .finally(() => {
          this.loading = false
        })
    },

    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting
    },

    async fetchTaskClaims() {
      let id = this.taskDetails.customer.id
      await this.$store.dispatch('CLAIMS_BY_TASK_ID', id)
      // this.loading.claim = false
    },
    async fetchTaskImages() {
      let param = {
        photo: {
          id: this.taskDetails.customer.id,
          site_id: this.taskDetails.site.id,
          paginate: 10
        }
      }
      await this.$store.dispatch('GET_ALL_PHOTO_BY_CUSTOMER_ID', param.photo)
      // this.loading.photos = false
    },
    onCustomerChange() {
      if (this.task.customer) {
        this.fetchTaskClaims()
        this.fetchTaskImages()
      }
    }
  }
}
</script>

<style src="./Create.scss" lang="scss" scoped>

</style>
