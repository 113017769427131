var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.task.claims.length > 0 ? _c('div', {
    staticClass: "claim-box mt-4 pa-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "claim-box-headline",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "#C62E2E",
      "size": "6"
    }
  }, [_vm._v("$dot")]), _vm._v(" クレーム ")], 1), _c('v-spacer'), _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    attrs: {
      "target": "_blank",
      "to": {
        name: 'CustomerViewDetailClaim',
        params: {
          customer_id: _vm.task.customer.id,
          site_id: _vm.task.site.id
        }
      },
      "rounded": "",
      "color": "white"
    }
  }, [_vm._v(" 一覧 "), _c('v-icon', {
    attrs: {
      "right": "",
      "size": "24"
    }
  }, [_vm._v(" $newTab ")])], 1)], 1)], 1)], 1), _vm.loading ? _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "size": "32",
      "indeterminate": ""
    }
  })], 1) : _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "10",
      "offset": "2"
    }
  }, [_c('v-row', [_vm.task.claims.length > 0 ? _vm._l(_vm.task.claims, function (claim, index) {
    return _c('v-col', {
      key: "claim-".concat(index),
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "claim-box-date",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(claim.created_at)) + " ")]), _c('v-col', {
      staticClass: "claim-box-tag mr-14",
      attrs: {
        "cols": "3"
      }
    }, _vm._l(claim.categories, function (category, index) {
      return _c('v-chip', {
        key: index,
        staticClass: "mr-3 claim-tag",
        attrs: {
          "color": "#8C5F5F",
          "text-color": "white",
          "label": "",
          "small": ""
        }
      }, [_vm._v(" " + _vm._s(category.name) + " ")]);
    }), 1), _c('v-col', {
      staticClass: "claim-box-content",
      attrs: {
        "cols": "6"
      }
    }, [_vm._v(" " + _vm._s(claim.description) + " ")])], 1)], 1);
  }) : _vm._e()], 2)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }