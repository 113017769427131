var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-text', [_c('v-container', [_c('v-row', {
    staticClass: "py-4"
  }, [_c('v-col', {
    staticClass: "text-center text-heading-1",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 写真管理 "), _c('div', [_c('v-btn', {
    staticClass: "close-button",
    attrs: {
      "depressed": "",
      "fab": "",
      "color": "#E27C7C",
      "width": "20",
      "height": "20"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close-dialog');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "14"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)]), _c('v-col', {
    staticClass: "text-center pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('input', {
    ref: "file",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": "image/png, image/gif, image/jpeg"
    },
    on: {
      "change": _vm.onFileChange
    }
  }), _c('v-btn', {
    attrs: {
      "height": "200",
      "block": "",
      "depressed": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.file.click();
      }
    }
  }, [!_vm.choosenPhoto ? _c('v-icon', {
    attrs: {
      "size": "180"
    }
  }, [_vm._v("$imagePlaceholder")]) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "#AD4545",
      "text": ""
    }
  }, [_vm._v(" - 写真削除 ")])], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "#336ABB",
      "text": ""
    }
  }, [_vm._v(" + 画像アップロード ")])], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("実施日")]), _c('div', [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "outlined": "",
      "placeholder": "日付選択",
      "hide-details": ""
    },
    model: {
      value: _vm.data.name,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "name", $$v);
      },
      expression: "data.name"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("説明文")]), _c('div', [_c('v-textarea', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "outlined": "",
      "placeholder": "画像の説明文",
      "hide-details": ""
    },
    model: {
      value: _vm.data.description,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "description", $$v);
      },
      expression: "data.description"
    }
  }), _c('div', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.data.description.length) + " / " + _vm._s(_vm.descriptionMaxLength) + " 文字 ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "#AD4545",
      "text": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("$trash")]), _vm._v(" 削除する ")], 1)], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "#4F55A7",
      "width": "100"
    },
    on: {
      "click": _vm.addTaskImage
    }
  }, [_vm._v("登録 ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }